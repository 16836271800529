<template>
	 <div class="table">
		<div class="crumbs">
		    <el-breadcrumb separator="/">
		        <el-breadcrumb-item><i class="el-icon-lx-cascades"></i> 天降星星</el-breadcrumb-item>
		    </el-breadcrumb>
		</div>
		 <div class="container">
			 <div class="handle-box">
<!-- 			     <el-input v-model="name" placeholder="筛选关键词" class="handle-input mr10"></el-input>
				 <el-button type="primary" icon="search" @click="getData(1)" style="margin-left:10px;">搜索</el-button> -->
				 <el-button type="primary" @click="handleConfig">设置</el-button>
				 <el-button type="primary"  @click="handlePros">中奖记录</el-button>
				 <el-button type="primary" @click="handleProsCate">游戏用户</el-button>
				 <el-button type="primary" @click="handleTask">任务列表</el-button>
				 <el-button type="primary" @click="handlePrize">奖品列表</el-button>
				 <el-button type="primary" style="float: right" @click="handleEdit(undefined, undefined, 1)">添加</el-button>
			 </div>
			 <el-table :data="data" border class="table" ref="multipleTable" @selection-change="handleSelectionChange">
				<el-table-column prop="id" label="编号" width="80" align="center" >
				 </el-table-column>
				 <el-table-column prop="title" label="标题" width="100" align="center" >
				  </el-table-column>
					<el-table-column prop="b_image" align="center" label="礼品图">
						<template slot-scope="scope">
							<el-popover
									placement="left"
									title=""
									width="500"
									trigger="hover">
								<img :src="scope.row.b_image" style="max-width: 100%" />
								<img slot="reference" :src="scope.row.b_image" :alt="scope.row.b_image" style="max-width: 130px; height: auto; max-height: 100px">
							</el-popover>
						</template>
					</el-table-column>

				  <el-table-column prop="score" label="分值" width="120" align="center" >
				  </el-table-column>
				  <el-table-column prop="count" label="总数" width="180" align="center" >
				  </el-table-column>
				  <el-table-column prop="width" label="星星尺寸" width="180" align="center" >
				  </el-table-column>
				  <el-table-column prop="status" label="状态" width="180" align="center" >
				  </el-table-column>

				  <el-table-column label="操作" align="center" width="180">
					  <template slot-scope="scope">
						  <el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row, 2)">编辑</el-button>
						  <el-button type="text" icon="el-icon-delete" class="red" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
					  </template>
				  </el-table-column>
			 </el-table>
			 <div class="pagination">
			     <el-pagination background @current-change="handleCurrentChange" layout="prev, pager, next" :total="sumPage">
			     </el-pagination>
			 </div>
		 </div>
        <!-- 弹出框 -->
		 <el-dialog v-loading="loading" :visible.sync="editVisible" width="50%">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="编辑" name="first">
                    <el-form ref="form" :rules="rules" :model="form" label-width="100px">
                        <el-form-item label="标题" prop="title">
                            <el-input v-model="form.title" style="width:400px" placeholder="请输入标题"></el-input>
                        </el-form-item>
                        <el-form-item label="分值" prop="score">
                            <el-input v-model="form.score" style="width:400px" placeholder="请输入分值"></el-input>
                        </el-form-item>                       
						<el-form-item label="显示图片">
							<el-upload
									class="avatar-uploader"
									name="image"
									with-credentials
									list-type="picture-card"
									:data="{id:this.form.imgid}"
									:action="uploadUrl()"
									:on-error="uploadError"
									:on-success="handleAvatarSuccess"
									:before-upload="beforeAvatarUpload"
									:on-progress="uploading"
									:show-file-list="false"
									:auto-upload="true"
									enctype="multipart/form-data">
								<img v-if="form.b_image" :src="form.b_image" class="avatar">
								<i v-else class="el-icon-plus"></i>
							</el-upload>
							<span style="color:red">建议尺寸200*200</span>
						</el-form-item>
						<el-form-item label="点击图片">
							<el-upload
									class="avatar-uploader"
									name="image"
									with-credentials
									list-type="picture-card"
									:data="{id:this.form.imgid2}"
									:action="uploadUrl()"
									:on-error="uploadError"
									:on-success="handleAvatarSuccess2"
									:before-upload="beforeAvatarUpload"
									:on-progress="uploading"
									:show-file-list="false"
									:auto-upload="true"
									enctype="multipart/form-data">
								<img v-if="form.b_image2" :src="form.b_image2" class="avatar">
								<i v-else class="el-icon-plus"></i>
							</el-upload>
							<span style="color:red">建议尺寸200*200</span>
						</el-form-item>
<!--                        <el-form-item label="需要的分数" prop="need_score">
                            <el-input v-model="form.need_score" style="width:150px" placeholder="请输入分数"></el-input>
                        </el-form-item> -->
						<el-form-item label="总数" prop="count">
						    <el-input v-model="form.count" style="width:150px" placeholder="请输入总数"></el-input>
						</el-form-item>
						<el-form-item label="尺寸" prop="width">
						    <el-input v-model="form.width" style="width:150px" placeholder="请输入星星尺寸"></el-input>
						</el-form-item>

                        <el-form-item label="是否显示">
                            <el-switch v-model="form.status"></el-switch>
                        </el-form-item>


                        <!--<el-form-item label="日期">-->
                        <!--<el-date-picker type="date" placeholder="选择日期" v-model="form.b_datetime" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>-->
                        <!--</el-form-item>-->
                    </el-form>
                </el-tab-pane>

            </el-tabs>


            <span slot="footer" class="dialog-footer">
                <el-button @click="editVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveEdit('form')">确 定</el-button>
            </span>

		  </el-dialog>		 
		  
		<el-dialog title="设置配置"  v-loading='loading' :visible.sync="dialogEmail" width="70%">
			<el-form :model="emailData" :rules="rules" label-width="150px">
			
                <el-form-item label="活动开始" prop="start_time">
                   <el-date-picker type="date" placeholder="选择日期" v-model="emailData.start_time" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                </el-form-item>
                <el-form-item label="活动结束" prop="end_time">
                   <el-date-picker type="date" placeholder="选择日期" v-model="emailData.end_time" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                </el-form-item>

                <el-form-item label="活动规则">
                    <vue-ueditor-wrap v-model="emailData.description" :config="myConfig"></vue-ueditor-wrap>
                </el-form-item>
						
						
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogEmail = false">关 闭</el-button>
				<el-button type="primary" @click="saveConfig()">确 定</el-button>
			</span>
		</el-dialog>
		  
		  <el-dialog title="中奖列表" v-loading="loading" :visible.sync="dialogProsTable" width="70%">
<!-- 			   <el-button type="primary"  @click="handleProsEdit(undefined, undefined, 1)">添加</el-button> -->
			   <el-table :data="prosTableData" border class="table">
				   <el-table-column prop="id" label="编号" width="100" align="center" sortable></el-table-column>
				   <el-table-column prop="nickname" label="用户昵称" width="180" align="center" ></el-table-column>
				    <el-table-column prop="tel" label="手机号" width="100" align="center" ></el-table-column>
					<el-table-column prop="name" label="奖品名称" width="180" align="center" ></el-table-column>
					<el-table-column prop="get_prize_time" label="获取时间" width="180" align="center" ></el-table-column>
					<el-table-column  label="状态" width="180" align="center" >
						<template slot-scope="scope">
							<span v-if="scope.row.is_use == 1">已使用</span>
							<span v-else>未使用</span>
						</template>
					</el-table-column>
<!-- 				   <el-table-column label="操作" align="center">
						  <template slot-scope="scope">
							  <el-button type="text" icon="el-icon-edit" @click="handleProsEdit(scope.$index, scope.row, 2)">编辑</el-button>
							  <el-button type="text" icon="el-icon-delete" class="red" @click="handleDeletePros(scope.$index, scope.row)">删除</el-button>
						  </template>
				   </el-table-column> -->
			   </el-table>
			   <div class="pagination">
			       <el-pagination background @current-change="handleProsCurrentChange" layout="prev, pager, next" :total="prosSumPage">
			       </el-pagination>
			   </div>
		  </el-dialog>
		  <el-dialog title="游戏记录" v-loading="loading" :visible.sync="UserRecordsTable" width="80%">
<!-- 			  <el-button type="primary"  @click="handleProsCateEdit(undefined, undefined, 1)">添加</el-button> -->
			  <el-table :data="UserRecordsTableData" border class="table">
				   <el-table-column prop="id" label="编号" width="180" align="center" sortable></el-table-column>
				   <el-table-column prop="nickname" label="昵称" width="180" align="center" ></el-table-column>
				   <el-table-column prop="name" label="分享人" width="180" align="center" ></el-table-column>
					<el-table-column prop="score" label="累计得分" width="180" align="center" ></el-table-column>
					<el-table-column prop="star_time" label="开始游戏时间" width="180" align="center" ></el-table-column>
<!-- 				   <el-table-column label="操作" align="center">
						  <template slot-scope="scope">
							  <el-button type="text" icon="el-icon-edit" @click="handleProsCateEdit(scope.$index, scope.row, 2)">编辑</el-button>
							  <el-button type="text" icon="el-icon-delete" class="red" @click="handleDeleteProsCate(scope.$index, scope.row)">删除</el-button>
						  </template>
				   </el-table-column> -->
				   
			  </el-table>
			  <div class="pagination">
			      <el-pagination background @current-change="handleUserRecordsChange" layout="prev, pager, next" :total="UserRecordsSumPage">
			      </el-pagination>
			  </div>
		  </el-dialog>
		  
		  
		  <el-dialog title="任务列表" v-loading="loading" :visible.sync="TaskTable" width="80%">
			  <el-button type="primary"  @click="handleTaskEdit(undefined, undefined, 1)">添加</el-button>
			  <el-table :data="TaskTableData" border class="table">
				    <el-table-column prop="name" label="任务名" width="180" align="center" sortable></el-table-column>
				    <el-table-column prop="key" label="任务标识" width="180" align="center" ></el-table-column>
					<el-table-column prop="b_image" align="center" label="图标">
						<template slot-scope="scope">
							<el-popover
									placement="left"
									title=""
									width="500"
									trigger="hover">
								<img :src="scope.row.b_image" style="max-width: 100%" />
								<img slot="reference" :src="scope.row.b_image" :alt="scope.row.b_image" style="max-width: 130px; height: auto; max-height: 100px">
							</el-popover>
						</template>
					</el-table-column>
					<el-table-column prop="btn_text" label="按钮文字" width="180" align="center" ></el-table-column>
				    <el-table-column  label="状态" width="180" align="center" >
						<template slot-scope="scope">
							<span v-if="scope.row.status == 1">显示</span>
							<span v-else>隐藏</span>
						</template>
					</el-table-column>
				   <el-table-column label="操作" align="center">
						  <template slot-scope="scope">
							  <el-button type="text" icon="el-icon-edit" @click="handleTaskEdit(scope.$index, scope.row, 2)">编辑</el-button>
							  <el-button type="text" icon="el-icon-delete" class="red" @click="handleDeleteTask(scope.$index, scope.row)">删除</el-button>
						  </template>
				   </el-table-column>
			  </el-table>
		  </el-dialog>		
			<el-dialog title="添加任务" v-loading="loading" ref="TaskForm" :visible.sync="taskFormAdd" width="50%">
				<el-form ref="TaskForm" :rules="ruless" :model="TaskForm" label-width="130px">
				    <el-form-item label="任务名" prop="name">
				        <el-input v-model="TaskForm.name" style="width:180px" placeholder="请输入任务名"></el-input>
				    </el-form-item>
					<el-form-item label="任务分数" prop="gold_number">
					    <el-input v-model="TaskForm.gold_number" style="width:180px" placeholder="请输入任务分数"></el-input>
					</el-form-item>
					<el-form-item label="按钮文字" prop="btn_text">
					    <el-input v-model="TaskForm.btn_text" style="width:180px" placeholder="请输入按钮文字"></el-input>
					</el-form-item>
					<el-form-item label="显示图片">
					    <el-upload
					            class="avatar-uploader"
					            name="image"
					            with-credentials
					            list-type="picture-card"
					            :data="{id:this.TaskForm.imgid}"
					            :action="uploadUrl()"
					            :on-error="uploadError"
					            :on-success="handleAvatarSuccess3"
					            :before-upload="beforeAvatarUpload"
					            :on-progress="uploading"
					            :show-file-list="false"
					            :auto-upload="true"
					            enctype="multipart/form-data">
					        <img v-if="TaskForm.b_image" :src="TaskForm.b_image" class="avatar">
					        <i v-else class="el-icon-plus"></i>
					    </el-upload>
					    <span style="color:red">建议尺寸200*200</span>
					</el-form-item>
					<el-form-item label="任务标识" prop="key">
					    <el-input v-model="TaskForm.key" style="width:180px" placeholder=""></el-input>
						<span>填写后不可更改</span>
					</el-form-item>
					<el-form-item label="任务地址" prop="url">
					    <el-input v-model="TaskForm.url" style="width:180px" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="任务说明" prop="desc">
					    <el-input v-model="TaskForm.desc" style="width:180px" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="限制次数" prop="limit_number">
					    <el-input v-model="TaskForm.limit_number" style="width:180px" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="任务排序" prop="sort">
					    <el-input v-model="TaskForm.sort" style="width:180px" placeholder=""></el-input>
						<span>排序值越小，排位越靠前</span>
					</el-form-item>
					<el-form-item label="限制类型" prop="limit_status">
						<el-radio v-model="TaskForm.limit_status"  label="0" >总次数</el-radio>
						<el-radio v-model="TaskForm.limit_status" label="1">当天计算</el-radio>
					</el-form-item>
					<el-form-item label="状态" prop="status">
						<el-radio v-model="TaskForm.status"  label="0" >隐藏</el-radio>
						<el-radio v-model="TaskForm.status" label="1">显示</el-radio>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
				    <el-button @click="taskFormAdd = false">关 闭</el-button>
				    <el-button type="primary" @click="saveTask('TaskForm')">确 定</el-button>
				</span>
			</el-dialog>
			

		  
		  <el-dialog title="奖品列表" v-loading="loading" :visible.sync="PrizeTable" width="80%">
			  <el-button type="primary"  @click="handlePrizeEdit(undefined, undefined, 1)">添加</el-button>
			  <el-table :data="PrizeTableData" border class="table">
				    <el-table-column prop="name" label="奖品名称" width="180" align="center" sortable></el-table-column>
					<el-table-column prop="b_image" align="center" label="奖品图">
						<template slot-scope="scope">
							<el-popover
									placement="left"
									title=""
									width="500"
									trigger="hover">
								<img :src="scope.row.b_image" style="max-width: 100%" />
								<img slot="reference" :src="scope.row.b_image" :alt="scope.row.b_image" style="max-width: 130px; height: auto; max-height: 100px">
							</el-popover>
						</template>
					</el-table-column>
				    <el-table-column prop="consume" label="消耗数量" width="180" align="center" ></el-table-column>
				    <el-table-column  label="状态" width="180" align="center" >
						<template slot-scope="scope">
							<span v-if="scope.row.status == 1">显示</span>
							<span v-else>隐藏</span>
						</template>
					</el-table-column>
				   <el-table-column label="操作" align="center">
						  <template slot-scope="scope">
							  <el-button type="text" icon="el-icon-edit" @click="handlePrizeEdit(scope.$index, scope.row, 2)">编辑</el-button>
							  <el-button type="text" icon="el-icon-delete" class="red" @click="handleDeletePrize(scope.$index, scope.row)">删除</el-button>
						  </template>
				   </el-table-column>
			  </el-table>
		  </el-dialog>		
			<el-dialog title="添加奖品" v-loading="loading" ref="PrizeForm" :visible.sync="prizeFormAdd" width="50%">
				<el-form ref="PrizeForm" :rules="rulesss" :model="PrizeForm" label-width="130px">
				    <el-form-item label="奖品名称" prop="name">
				        <el-input v-model="PrizeForm.name" style="width:180px" placeholder="请输入奖品名称"></el-input>
				    </el-form-item>
					<el-form-item label="奖品图片">
					    <el-upload
					            class="avatar-uploader"
					            name="image"
					            with-credentials
					            list-type="picture-card"
					            :data="{id:this.PrizeForm.imgid}"
					            :action="uploadUrl()"
					            :on-error="uploadError"
					            :on-success="handleAvatarSuccess4"
					            :before-upload="beforeAvatarUpload"
					            :on-progress="uploading"
					            :show-file-list="false"
					            :auto-upload="true"
					            enctype="multipart/form-data">
					        <img v-if="PrizeForm.b_image" :src="PrizeForm.b_image" class="avatar">
					        <i v-else class="el-icon-plus"></i>
					    </el-upload>
					    <span style="color:red">建议尺寸200*200</span>
					</el-form-item>
					<el-form-item label="消耗数量" prop="consume">
					    <el-input v-model="PrizeForm.consume" style="width:180px" placeholder="请输入消耗数量"></el-input>
					</el-form-item>
					<el-form-item label="排序" prop="sort">
					    <el-input v-model="PrizeForm.sort" style="width:180px" placeholder=""></el-input>
						<span>排序值越小，排位越靠前</span>
					</el-form-item>
					<el-form-item label="状态" prop="status">
						<el-radio v-model="PrizeForm.status"  label="0" >隐藏</el-radio>
						<el-radio v-model="PrizeForm.status" label="1">显示</el-radio>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
				    <el-button @click="prizeFormAdd = false">关 闭</el-button>
				    <el-button type="primary" @click="savePrize('PrizeForm')">确 定</el-button>
				</span>
			</el-dialog>
			
			
			<!-- 删除提示框 -->
			<el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
			    <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
			    <span slot="footer" class="dialog-footer">
			        <el-button @click="delVisible = false">取 消</el-button>
			        <el-button type="primary" @click="deleteRow(delMode)">确 定</el-button>
			    </span>
			</el-dialog>			
			
			
			

	</div>
</template>

<script>
	import 'quill/dist/quill.core.css';
	import 'quill/dist/quill.snow.css';
	import 'quill/dist/quill.bubble.css';
	import {quillEditor, Quill} from 'vue-quill-editor'
	import {container, ImageExtend, QuillWatch} from 'quill-image-extend-module';
	Quill.register('modules/ImageExtend', ImageExtend)
	
	export default {
		name: 'basetable',
		components: {
		    quillEditor
		},
		 data() {
			 return{
				 myConfig: {
				     // 编辑器不自动被内容撑高
				     autoHeightEnabled: false,
				     // 初始容器高度
				     initialFrameHeight: 600,
				     // 初始容器宽度
				     initialFrameWidth: '90%',
				     zIndex: 9999,
				     // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
				     serverUrl: this.$api.editorUrl,
				     // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
				     UEDITOR_HOME_URL: '/ueditor/'
				 },
				 tableData: [],
				 AddOrSave:'',  //1表示添加，2表示更新
				 TaskAddOrSave:'',  //1表示添加，2表示更新
				 PrizeAddOrSave:'',  //1表示添加，2表示更新
				 cur_page: 1,  //当前页
				 number: 10,  //每页显示条数
				 sumPage:10,  //总页数
				 pros_cur_page:1,
				 user_records_page:1,
				 task_page:1,
				 prize_page:1,
				 prosSumPage:10, 
				 UserRecordsSumPage:10,
				 TaskSumPage:10,
				 PrizeSumPage:10,
				 prosTableData:[],
				 prosCateTableData:[],
				 UserRecordsTableData:[],
				 TaskTableData:[],
				 PrizeTableData:[],
				 multipleSelection: [],
				 dialogProsTable:false,
				 UserRecordsTable:false,
				 TaskTable:false,
				 PrizeTable:false,
				 dialogAddProsCate:false,
				 taskFormAdd:false,
				 prizeFormAdd:false,
				 dialogAddPros:false,
				 editVisible: false,
				 start_time:'',
				 end_time:'',
				 name:'',
				 mobile:'',
				 loading:false, //加载中
				 del_list: [],
				 activeName: 'first',  //默认选中tab
				 TaskForm:{
					 id:'',
					 name:'',
					 key:'',
					 icon:'',
					 gold_number:'',
					 btn_text:'',
					 desc:'',
					 limit_status:0,
					 limit_number:'',
					 sort:0,
					 url:'',
					 status:0,
					 imgid: null,
					 b_image: null,
				 },
				 PrizeForm:{
					 id:'',
					 name:'',
					 key:'',
					 icon:'',
					 gold_number:'',
					 btn_text:'',
					 desc:'',
					 limit_status:0,
					 limit_number:'',
					 sort:0,
					 url:'',
					 status:0,
					 imgid: null,
					 b_image: null,
				 },				 
				 form: {
				     id: '',
				     ordernumber: '',
				     nickname: '',
				     name: '',
					 imgid: null,
					 b_image: null,
					 imgid2: null,
					 b_image2: null,

				 },
				 detailList: [],
				 prosCateForm:{
					 id:'',
					 cate_name:'',
					 is_show:1,
					 sort:0,
					 choose_pro_max:0,
					 time_peri:0
				 },
				 delMode:'Star',
				 delVisible:false,
				 dialogEmail:false,
				 appointid:'',
				 rules: {
					title: [
                        { required: true, message: '请输入标题', trigger: 'blur' }
                    ],
                    score: [
                        { required: true, message: '请输入分值', trigger: 'blur' }
                    ],                   
                    // need_score: [
                    //     { required: true, message: '请输入分数', trigger: 'blur' }
                    // ],
                    count:[
                        { required: true, message: '请输入总数', trigger: 'blur' }
                    ],
                    width:[
                        { required: true, message: '请输入星星尺寸', trigger: 'blur' }
                    ],
					show_img: [
					    { required: true, message: '请上传显示图片', trigger: 'blur' }
					],
					click_img: [
					    { required: true, message: '请上传点击图片', trigger: 'blur' }
					],                   
					start_time: [
					    { required: true, message: '活动开始', trigger: 'blur' }
					],
					end_time:[
					    { required: true, message: '活动结束', trigger: 'blur' }
					],
                 },
				 ruless: {
					name: [
                        { required: true, message: '请输入任务名', trigger: 'blur' }
                    ],
					gold_number: [
                        { required: true, message: '请输入任务分数', trigger: 'blur' }
                    ],
					btn_text: [
					    { required: true, message: '请输入按钮文字', trigger: 'blur' }
					],
					icon: [
					    { required: true, message: '请上传按钮图', trigger: 'blur' }
					],
					key: [
					    { required: true, message: '请输入任务标识', trigger: 'blur' }
					],

                 },
				 rulesss: {
					name: [
                        { required: true, message: '请输入奖品名称', trigger: 'blur' }
                    ],
					gold_number: [
                        { required: true, message: '请上传缩略图', trigger: 'blur' }
                    ],
					btn_text: [
					    { required: true, message: '请输入消耗数量', trigger: 'blur' }
					],
                 },				 
				 emailData:{
					 id:'',
					 president_fir:'',
					 president_fir_img:'',
				 }
			 }
		 },
		 created() {
		     this.getData();
		
		 },
		 computed: {
		     data() {
		         return this.tableData.filter((d) => {
		             let is_del = false;
		             for (let i = 0; i < this.del_list.length; i++) {
		                 if (d.name === this.del_list[i].name) {
		                     is_del = true;
		                     break;
		                 }
		             }
		             return d;
		         })
		     }
		 },
		  methods: {
			  //设置上传图片接口地址
			  uploadUrl(){
			      var url=this.$api.uploadUrl + "/Images/upload";
			      return url
			  },
			  handleClick(tab, event) {
			      // console.log(tab, event);
			  },
			  //图片上传之前
			  beforeAvatarUpload(file){
			      // console.log(file);
			  },
			  //正在上传中
			  uploading(event, file, fileList){
			      // console.log(event);
			      // console.log(file);
			      // console.log(fileList);
			  },
			  //图片上传失败
			  uploadError(err){
			      this.$message.error(err.msg);
			  },
			  //图片上传成功
			  handleAvatarSuccess(res, file){
			      
			      this.form.imgid=res.data;
			      this.form.b_image = URL.createObjectURL(file.raw);

			      this.$message.success(res.msg);
			  },
			  handleAvatarSuccess2(res, file){
			      
			      this.form.imgid2=res.data;
			      this.form.b_image2 = URL.createObjectURL(file.raw);

			      this.$message.success(res.msg);
			  },
			  handleAvatarSuccess3(res, file){
			      
			      this.TaskForm.imgid=res.data;
			      this.TaskForm.b_image = URL.createObjectURL(file.raw);

			      this.$message.success(res.msg);
			  },
			  handleAvatarSuccess4(res, file){
			      
			      this.PrizeForm.imgid=res.data;
			      this.PrizeForm.b_image = URL.createObjectURL(file.raw);

			      this.$message.success(res.msg);
			  },
			  getData(){
				  var params=this.$qs.stringify({
				      name: this.name,
				      mobile: this.mobile,
					  pageIndex: this.cur_page,
					  number: this.number,
					  activity_type:3,
				  });
				  // console.log(params);
				  this.$api.post('StarPowder/index', params, res => {
				      this.tableData = res.data.list;
				      this.sumPage = res.data.sumPage*10;
				      this.cur_page = res.data.currentPage;
				      console.log(res);
				  }, err => {
				      this.tableData = [];
				      this.sumPage = 1*10;
				      this.cur_page = 1;
				      this.$message.error(err.msg);
				  });
			  },
			handleEdit(index, row, status) {
				// var params=this.$qs.stringify({
				//     id: row.id,
				// });
				this.AddOrSave=status;
			    //如果是添加则把form清空
			    if(status==1){
			        this.form = {
			            id: null,
			            title: null,
			            score: null,
			            count: null,
			            width: null,
			            start_value: null,
			            show_img: null,
						click_img:null,
			            status: 0,
						imgid: null,
						b_image: null,
						imgid2: null,
						b_image2: null,
			        };
			    }
			    if(index!=undefined && row!=undefined){

			        this.idx = index;
			        const item = this.tableData[index];
				
			        this.form = {
			            id: item.id,
			            title: item.title,
			            score: item.score,
			            count: item.count,
			            width: item.width,
						show_img: item.show_img,
						click_img:item.click_img,
			            status: item.status==1?true:false,
						imgid: item.imgid,
						b_image: item.b_image,
						imgid2: item.imgid2,
						b_image2: item.b_image2,

			        };
			    }
				//console.log(this.form);
			    this.activeName = "first";  //默认选中第一个Tab
			    this.editVisible = true;

			},
            saveEdit(formName) {// 保存编辑
                // this.$set(this.tableData, this.idx, this.form);
                this.$refs[formName].validate((valid) => {
                    if (valid) {
						if(!this.form.imgid){
							this.$message.error('请上传图片');
							return false;
						}
						if(!this.form.imgid2){
							this.$message.error('请上传图片');
							return false;
						}
                        this.editVisible = false;
                        var params=null;
                        //1表示添加，2表示更新
                        if(this.AddOrSave==1){
                            params=this.$qs.stringify({
                                title: this.form.title,
                                score: this.form.score,
                                count: this.form.count,
								width:this.form.width,
                                show_img: this.form.show_img,
                                click_img: this.form.click_img,
                                status: this.form.status ? 1 : 0,
								imgid: this.form.imgid,
								imgid2: this.form.imgid2,
                            });
                        }else{
                            params=this.$qs.stringify({
                                id: this.form.id,
                                title: this.form.title,
                                score: this.form.score,
                                count: this.form.count,
								width:this.form.width,
                                show_img: this.form.show_img,
								show_img: this.form.show_img,
                                click_img: this.form.click_img,
                                status: this.form.status ? 1 : 0,
								imgid: this.form.imgid,
								imgid2: this.form.imgid2,
                            });
                        }

                        this.$api.post('StarPowder/addStarPowder', params, res => {
                            this.getData();
                            this.$message.success(res.msg);
                            //console.log(res);

                        }, err => {
                            this.$message.error(err.msg);
                        });
                    }else{
                        console.log("请填写所需数据");
                        return false;
                    }
                });
                // this.$message.success(`修改第 ${this.idx+1} 行成功`);
            },
			  handlePros(){	
				var params=this.$qs.stringify({
					  activity_type: 3,
					  number: this.number,
					  pageIndex: this.pros_cur_page,
				});
			  	this.$api.post('StarPowder/recordIndex', params, res => {
			  	    this.prosTableData = res.data.list;
					this.dialogProsTable = true;
					this.prosSumPage = res.data.sumPage*10;
					this.pros_cur_page = res.data.currentPage;
			  	}, err => {
				      this.prosTableData = [];
				      this.prosSumPage = 1*10;
				      this.pros_cur_page = 1;
				      this.$message.error(err.msg);
				  });
			  },
			  handleProsCate(){	//游戏用户
				var params=this.$qs.stringify({
					  number: this.number,
					  pageIndex: this.user_records_page,
				});
			  	this.$api.post('StarPowder/UserRecords', params, res => {
			  	   this.UserRecordsTableData = res.data.list;
				   this.UserRecordsTable = true;
				   this.UserRecordsSumPage = res.data.sumPage*10;
				   this.user_records_page = res.data.currentPage;

			  	}, err => {
				    this.UserRecordsTableData = [];
				    this.UserRecordsSumPage = 1*10;
				    this.user_records_page = 1;
				    this.$message.error(err.msg);
				  });
			  },			  
			  handleTask(){	//任务列表
				var params=this.$qs.stringify({
					  number: this.number,
					  pageIndex: this.user_records_page,
				});
			  	this.$api.post('StarPowder/taskIndex', params, res => {
					this.TaskTableData = res.data.list;
					this.TaskTable = true;
					this.TaskSumPage = res.data.sumPage*10;
					this.task_page = res.data.currentPage;

			  	}, err => {
				    this.TaskTableData = [];
				    this.TaskSumPage = 1*10;
				    this.task_page = 1;
				    this.$message.error(err.msg);
				  });
			  },
			  handleTaskEdit(index, row, status){
				   if(status  ==  1){
						this.TaskForm = {
							 name:null,
							 key:null,
							 icon:null,
							 gold_number:null,
							 btn_text:null,
							 desc:null,
							 limit_status:'1',
							 limit_number:null,
							 sort:0,
							 url:null,
							 status:'1',
							 imgid: null,
							 b_image: null,
						};
						//console.log(this.TaskForm);
				   }else{
					  var item = this.TaskTableData[index];

						this.TaskForm ={
						  id:item.id,
						  name:item.name,
						  key:item.key,
						  icon:item.icon,
						  gold_number:item.gold_number,
						  btn_text:item.btn_text,
						  desc:item.desc,
						  limit_status:item.limit_status,
						  limit_number:item.limit_number,
						  sort:item.sort,
						  url:item.url,
						  status:item.status,
						  imgid: item.imgid,
						  b_image: item.b_image,
						}
					  //console.log(this.prosCateForm)
				   }
					this.taskFormAdd = true
			  },
			  saveTask(formName){
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if(!this.TaskForm.imgid){
							this.$message.error('请上传图片');
							return false;
						}
					   var params=this.$qs.stringify({
						   id:this.TaskForm.id,
						   name:this.TaskForm.name,
						   key:this.TaskForm.key,
						   icon:this.TaskForm.icon,
						   gold_number:this.TaskForm.gold_number,
						   btn_text:this.TaskForm.btn_text,
						   desc:this.TaskForm.desc,
						   limit_status:this.TaskForm.limit_status,
						   limit_number:this.TaskForm.limit_number,
						   sort:this.TaskForm.sort,
						   url:this.TaskForm.url,
						   status:this.TaskForm.status,
						   imgid: this.TaskForm.imgid,
					   })
					  this.$api.post('StarPowder/addTask',params,res=>{
						  this.taskFormAdd = false
						  this.handleTask()
						  this.$message.success(res.msg);
					  },err=>{
						   this.$message.error(err.msg);
					  });
                    }else{
                        console.log("请填写所需数据");
                        return false;
                    }				  
				  
				});  
			  },
			  handlePrize(){	//奖品列表
				var params=this.$qs.stringify({
					  number: this.number,
					  pageIndex: this.user_records_page,
				});
			  	this.$api.post('StarPowder/prizeIndex', params, res => {
					this.PrizeTableData = res.data.list;
					this.PrizeTable = true;
					this.PrizeSumPage = res.data.sumPage*10;
					this.prize_page = res.data.currentPage;

			  	}, err => {
				    this.PrizeTableData = [];
				    this.PrizeSumPage = 1*10;
				    this.prize_page = 1;
				    this.$message.error(err.msg);
				  });
			  },
			  handlePrizeEdit(index, row, status){
				   if(status  ==  1){
						this.PrizeForm = {
							 name:null,
							 images:null,
							 consume:null,
							 sort:null,
							 create_time:null,
							 update_time:null,
							 status:'0',
							 imgid: null,
							 b_image: null,
						};
						console.log(this.PrizeForm);
				   }else{
					  var item = this.PrizeTableData[index];

						this.PrizeForm ={
						  id:item.id,
						  name:item.name,
						  images:item.images,
						  consume:item.consume,
						  sort:item.sort,
						  create_time:item.create_time,
						  update_time:item.update_time,
						  status:item.status,
						  imgid: item.imgid,
						  b_image: item.b_image,
						}
					  //console.log(this.prosCateForm)
				   }
					this.prizeFormAdd = true
			  },
			  savePrize(formName){
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if(!this.PrizeForm.imgid){
							this.$message.error('请上传图片');
							return false;
						}
					   var params=this.$qs.stringify({
						   id:this.PrizeForm.id,
						   name:this.PrizeForm.name,
						   key:this.PrizeForm.key,
						   icon:this.PrizeForm.icon,
						   gold_number:this.PrizeForm.gold_number,
						   btn_text:this.PrizeForm.btn_text,
						   desc:this.PrizeForm.desc,
						   limit_status:this.PrizeForm.limit_status,
						   limit_number:this.PrizeForm.limit_number,
						   sort:this.PrizeForm.sort,
						   url:this.PrizeForm.url,
						   status:this.PrizeForm.status,
						   imgid:this.PrizeForm.imgid,
					   })
					  this.$api.post('StarPowder/addPrize',params,res=>{
						  this.prizeFormAdd = false
						  this.handlePrize()
						  this.$message.success(res.msg);
					  },err=>{
						   this.$message.error(err.msg);
					  });
                    }else{
                        console.log("请填写所需数据");
                        return false;
                    }				  
				  
				});  
			  },
			  handleDelete(index, row) {
			      this.idx = index
			      this.form = row
			      this.delVisible = true
				  this.delMode = 'Star'
			  	
			  },
			  handleDeleteTask(index,row){
				  this.TaskForm = row
				  this.delVisible = true
				  this.delMode = 'task'
			  },
			  handleDeletePrize(index,row){
				  this.PrizeForm = row
				  this.delVisible = true
				  this.delMode = 'prize'

			  },			  
			  deleteRow(delmode){

				  var url = ''
				  var id = ''
					if(delmode == 'Star'){
						url = 'StarPowder/delStarPowder';
						id = this.form.id;
					}else if(delmode == 'task'){
						url = 'StarPowder/delTask';
						id = this.TaskForm.id;
					}else if(delmode == 'prize'){
						url = 'StarPowder/delPrize';
						id = this.PrizeForm.id;
					}

					var params=this.$qs.stringify({
						id: id
				  });
				  this.$api.post(url, params, res => {
					  console.log(res);
					//this.handlePros();

					if(delmode == 'Star'){
						this.getData();
					}else if(delmode == 'task'){
						this.handleTask();
					}else if(delmode == 'prize'){
						this.handlePrize();
					}
					this.$message.success(res.msg);
					
					//this.$message.success(res.msg+res.data+"条数据");
				  }, err => {
				      this.$message.error(err.msg);
				  });
				  this.delVisible = false;
			  },
			  handleConfig(){
					var params = this.$qs.stringify({

					})
					this.dialogEmail = true
				    this.$api.post('StarPowder/system_set', params,res=>{
						this.emailData =  res.list
					 
				  },err => {
				    this.emailData = []
				    this.$message.error(err.msg);
				 });
			  },
			  saveConfig(){
				  var params=this.$qs.stringify({
						start_time:this.emailData.start_time,
						end_time:this.emailData.end_time,
						description:this.emailData.description,

				  });

				  this.$api.post('StarPowder/saveSetting', params, res => {
						this.dialogEmail =  false
						this.$message.success(res.msg);
				  }, err => {
				      this.$message.error(err.msg);
				  });
			  },
			  // 分页导航
			  handleCurrentChange(val) {
			      this.cur_page = val;
			      this.getData();
			  },
			  handleProsCurrentChange(val){
				  this.pros_cur_page = val
				   this.handlePros()
			  },
			  handleUserRecordsChange(val){
				  this.user_records_page = val
				   this.handleProsCate()
			  },
			  handleSelectionChange(val) {
			      this.multipleSelection = val;
			  }
			
		  }
	}
</script>

<style scoped>
	.handle-box {
	    margin-bottom: 20px;
	}
	
	.handle-select {
	    width: 120px;
	}
	
	.handle-input {
	    width: 300px;
	    display: inline-block;
	}
	.del-dialog-cnt{
	    font-size: 16px;
	    text-align: center
	}
	.table{
	    width: 100%;
	    font-size: 14px;
	}
	.red{
	    color: #ff0000;
	}
</style>
